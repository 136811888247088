import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import LoginDetail from "../../Layout/LoginDetail/LoginDetail";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import CustomSelect from "../../Ui/CustomSelect/CustomSelect";
import InputCustom from "../../Ui/input/InputCustom";
import AntierLogo from "../../Assets/AdminImages/AntierLogo.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as COMMON_FUNCTION from "../../../Common/formValidations";
import { useForm, Controller } from "react-hook-form";

import "./ForgotPassword.scss";
import { useDispatch, useSelector } from "react-redux";
import { forgetPasswordtService } from "../../../Utilities/Redux/Services/ForgetPassword.Service";
import { useNavigate } from "react-router-dom";
function ForgotPassword() {
  const { success, message, error } = useSelector((state) => state.toast);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setactiveTab] = useState("email");
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(COMMON_FUNCTION.FORGOT_VALIDATION),
  });

  const onSubmit = (data) => {
    dispatch(
      forgetPasswordtService({
        email: data.email,
      })
    );
  };

  useEffect(() => {
    success && navigate("/");
  }, [success]);

  return (
    <div className="flexAuto">
      <div className="forgotScreen">
        <div className="container">
          <div>
            <Row>
              <Col xs={24} md={14} className="forgotScreen__leftCol">
                <LoginDetail
                  topimage={
                    <span>
                      <img src={AntierLogo} width={300} />
                    </span>
                  }
                />
              </Col>
              <Col xs={24} md={10} className="forgotScreen__rytCol">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h2>Reset Your Password</h2>
                  <div className="forgotScreen__loginLeft">
                    <div className="forgotScreen__inputSec">
                      {activeTab == "email" ? (
                        <div className="forgotScreen__innerInput">
                          {/* <InputCustom labelcustom="sizeMange" basicInput inputCustum label="Email" /> */}
                          <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                              <InputCustom
                                basicInput
                                inputCustum
                                label="Email"
                                {...field}
                              />
                            )}
                          />
                          {errors?.email?.message !== "" && (
                            <p style={{ color: "red" }}>
                              {errors?.email?.message}
                            </p>
                          )}
                        </div>
                      ) : (
                        <div className="forgotScreen__innerInput ">
                          <label className="forgotScreen__selectlabel">
                            Phone Number
                          </label>
                          <div className="forgotScreen__selectInput">
                            <CustomSelect images />
                            <InputCustom basicInput inputCustum />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="loginScreen__iconBtn">
                      <ButtonCustom
                        garyBtn
                        large
                        label="Submit"
                        type="submit"
                      />
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
