import { createSlice } from "@reduxjs/toolkit";
import { apiListService } from "../Services/Api.Service";
const initialState = {
  loading: false,
  error: false,
  apliListRecord: [],
};

const apliListSlice = createSlice({
  name: "users-slice",
  initialState,
  reducers: {},
  extraReducers: {
    [apiListService.pending]: (state, action) => {
      state.loading = true;
    },
    [apiListService.fulfilled]: (state, action) => {
      state.apliListRecord = action?.payload?.apiListData;
      state.loading = false;
    },

    [apiListService.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {} = apliListSlice.actions;
export default apliListSlice.reducer;
