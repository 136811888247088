import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../Api/Index";
import { setToast } from "../Slices/CommonToast.Slice";
import { API_BASE_URL } from "../../../Common/Constant/Index";
import { setJwtExpireError } from "../../../Common/CommonFuntion";

export const registerService = createAsyncThunk(
  "register-service",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `${API_BASE_URL}auth/register`;
      let res = await API.post(payload)(url, payload);
      dispatch(
        setToast({
          message: res?.message,
          success: true,
          error: false,
        })
      );
    } catch (e) {
      dispatch(
        setToast({
          message: e?.message,
          success: false,
          error: true,
        })
      );
      return rejectWithValue(e);
    }
  }
);
