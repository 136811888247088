import React from "react";
import "../SettingsComponents.scss";
import "../../Pages/Login/Login.scss";
import { Col, Row } from "antd";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import InputCustom from "../../Ui/input/InputCustom";
import CustomCard from "../../Ui/Card/CustomCard";
import key from "../../Assets/Images/Key.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as COMMON_FUNCTION from "../../../Common/formValidations";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordService } from "../../../Utilities/Redux/Services/ChangePassword";
import { useEffect } from "react";

function ChangePassword() {
  const dispatch = useDispatch();
  const { success } = useSelector((state) => state?.toast);
  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(COMMON_FUNCTION.CHANGE_PASSWORD_VALIDATION),
  });

  const onSubmit = (data) => {
    dispatch(
      changePasswordService({
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      })
    );
  };

  useEffect(() => {
    if (success) {
      reset();
    }
  }, [success]);
  return (
    <div className="annoucements_block card-outer">
      <CustomCard text="Change Password" src={key}>
        <div className="p-20">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="loginScreen__inputSec">
              <div className="loginScreen__innerInput">
                {/* <InputCustom
                    labelcustom="sizeMange"
                    basicInput
                    inputCustum
                    label="Enter Old Password"
                  /> */}
                <Controller
                  name="oldPassword"
                  control={control}
                  render={({ field }) => (
                    <InputCustom
                      passwordInput
                      inputCustum
                      eyeIcon
                      label="Enter Old Password"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value.trim());
                      }}
                    />
                  )}
                />
                {errors?.oldPassword?.message !== "" && (
                  <p style={{ color: "red" }}>{errors?.oldPassword?.message}</p>
                )}
              </div>
              <div className="loginScreen__innerInput">
                {/* <InputCustom
                    labelcustom="sizeMange"
                    basicInput
                    inputCustum
                    label="Enter New Password"
                  /> */}
                <Controller
                  name="newPassword"
                  control={control}
                  render={({ field }) => (
                    <InputCustom
                      passwordInput
                      inputCustum
                      eyeIcon
                      label="Enter New Password"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value.trim());
                      }}
                    />
                  )}
                />
                {errors?.newPassword?.message !== "" && (
                  <p style={{ color: "red" }}>{errors?.newPassword?.message}</p>
                )}
              </div>
              <div className="loginScreen__innerInput">
                {/* <InputCustom
                    labelcustom="sizeMange"
                    basicInput
                    inputCustum
                    label="Confirm New Password"
                  /> */}
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <InputCustom
                      passwordInput
                      inputCustum
                      eyeIcon
                      label="Confirm New Password"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value.trim());
                      }}
                    />
                  )}
                />
                {errors?.confirmPassword?.message !== "" && (
                  <p style={{ color: "red" }}>
                    {errors?.confirmPassword?.message}
                  </p>
                )}
              </div>
            </div>
            <div>
              <ButtonCustom label="CHANGE PASSWORD" small type="submit" />
            </div>
          </form>
        </div>
      </CustomCard>
    </div>
  );
}

export default ChangePassword;
