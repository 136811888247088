import {
  AppstoreOutlined,
  BellOutlined,
  RocketOutlined,
} from "@ant-design/icons";
export const navigationItems = [
  {
    key: "1",
    icon: (
      <AppstoreOutlined
        style={{ color: "#18a2dc", fontSize: 20, fontWeight: 600 }}
      />
    ),
    label: "Overview",
    route: "/overview",
  },
  // {
  //   key: "2",

  //   icon: (
  //     <span className="anticon anticon-desktop ant-menu-item-icon">
  //       <RocketOutlined
  //         style={{ color: "#18a2dc", fontSize: 20, fontWeight: 600 }}
  //       />
  //     </span>
  //   ),
  //   label: "Plan & Billing",
  //   route: "/plan/billing",
  // },
  // {
  //   key: "3",
  //   icon: (
  //     <span className="anticon anticon-desktop ant-menu-item-icon">
  //       <BellOutlined
  //         style={{ color: "#18a2dc", fontSize: 20, fontWeight: 600 }}
  //       />
  //     </span>
  //   ),
  //   label: "Notifications",
  //   route: "/notifications",
  // },
];
