import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../Api/Index";
import { API_BASE_URL } from "../../../Common/Constant/Index";
import { getSecretKey } from "../../../Components/Pages/Dashboard/Overview";
import { setJwtExpireError } from "../../../Common/CommonFuntion";

export const usersService = createAsyncThunk(
  "allUsers-service",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const token = getState().auth.token;
    try {
      let url = `${API_BASE_URL}dashboard/userdata`;
      let res = await API.get(token)(url, payload);
      await getSecretKey(res?.SecurityKey);
      return { users: res?.data, status: true };
    } catch (e) {
      if ((e.message = "Unauthorize Request")) {
        setJwtExpireError(dispatch, e);
      }
      return rejectWithValue(e);
    }
  }
);
