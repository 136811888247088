import React, { useState } from "react";
import "./Login.scss";
import { Col, Row } from "antd";
import InputCustom from "../../Ui/input/InputCustom.jsx";
import { ButtonCustom } from "../../Ui/button/ButtonCustom.jsx";
import { useNavigate } from "react-router-dom";
import LoginDetail from "../../Layout/LoginDetail/LoginDetail";
import AntierLogo from "../../Assets/AdminImages/AntierLogo.png";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as COMMON_FUNCTION from "../../../Common/formValidations";
import { useDispatch } from "react-redux";
import { loginService } from "../../../Utilities/Redux/Services/Login.Services";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(COMMON_FUNCTION.LOGIN_VALIDATION) });

  const onSubmit = (data) => {
    dispatch(
      loginService({
        email: data.email,
        password: data.password,
      })
    );
  };
  return (
    <div className="flexAuto">
      <div className="loginScreen">
        <div className="container">
          <Row style={{ alignItems: "center" }}>
            <Col xs={24} md={12} className="loginScreen__leftCol">
              <LoginDetail
                topimage={
                  <span>
                    <img src={AntierLogo} width={300} />
                  </span>
                }
              />
            </Col>
            <Col xs={24} md={12} className="loginScreen__rytCol">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="loginScreen__loginLeft">
                  <div className="loginScreen__logInContact">
                    <h2>CoinZone Account Login</h2>
                  </div>
                  <div className="loginScreen__inputSec">
                    <div className="loginScreen__innerInput">
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <InputCustom
                            basicInput
                            inputCustum
                            label="Email"
                            {...field}
                          />
                        )}
                      />
                      <p style={{ color: "red" }}>{errors.email?.message}</p>
                    </div>

                    <div className="loginScreen__innerInput">
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <InputCustom
                            passwordInput
                            inputCustum
                            eyeIcon
                            label="Password"
                            {...field}
                            onChange={(e) => {
                              field.onChange(e.target.value.trim());
                            }}
                          />
                        )}
                      />
                      <p style={{ color: "red" }}>{errors.password?.message}</p>
                      <span
                        style={{
                          float: "right",
                          color: "#0b9eda",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                        onClick={() => navigate("/forgotpassword")}
                      >
                        Forgot Password?
                      </span>
                    </div>
                  </div>
                  <div
                    style={{ marginTop: "20px" }}
                    className="loginScreen__logRegBtn"
                  >
                    <ButtonCustom
                      label="Submit"
                      yellowBtn
                      large
                      type="submit"
                    />
                  </div>
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Login;
