import React from "react";
import { Select } from "antd";
import IndiaFlag from "../../Assets/Images/btcimg.svg";
import './CustomSelect.scss'
function CustomSelect(props) {
    const data = [
        {
            value: "Text1",
            img : IndiaFlag
        },
        {
            value: "Text2",
            img : IndiaFlag
        },
        {
            value: "Text3",
            img : IndiaFlag
        },{
            value: "Text4",
            img : IndiaFlag
        }

    ]
  const { images, outPagesSelect , innerPagesSelect, innerTxtField,label,labelcustom, fillBg,CustomSelectStyle, paddingLeft } = props;
  const { Option } = Select;
  return (
    <div className="SelectOuter">
       {label ? <label className={`label ${labelcustom}`}>{label}</label> : null}
      <Select {...props} className={`customSelect ${outPagesSelect ? "outPagesSelect" : ""} ${innerPagesSelect ? "innerPagesSelect" : ""} ${fillBg && "fillBg"} ${CustomSelectStyle} ${paddingLeft && "paddingLeft"}`} defaultValue={data[0].value} >
       {data.map((data)=> <Option value={data.value}>
        {images ?  <img src={data.img} /> : null}
          {data.value}
        </Option>)}
        
      </Select>
      {innerTxtField ? <span className="innerTxtField">
        {innerTxtField}
      </span> : null}
    </div>
  );
}

export default CustomSelect;
