import { createSlice } from "@reduxjs/toolkit";
import { getNotificationsService } from "../Services/NotificationsStatus.Service";

const initialState = {
  getNotifications: [],
  loading: false,
  error: false,
};

const notificationsSlice = createSlice({
  name: "notification-slice",
  initialState,
  reducers: {},
  extraReducers: {
    [getNotificationsService.pending]: (state, action) => {
      state.loading = true;
    },
    [getNotificationsService.fulfilled]: (state, action) => {
      state.getNotifications = action?.payload?.getNotifications;
      state.loading = false;
    },

    [getNotificationsService.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {} = notificationsSlice.actions;
export default notificationsSlice.reducer;
