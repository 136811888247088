import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Mainlayout from "./Components/Layout/Mainlayout/Mainlayout";
import Login from "./Components/Pages/Login/Login";
import DashboardLayout from "./Components/Layout/DashboardLayout/DashboardLayout";
import PageNotFound from "./Components/Pages/PageNotFound/PageNotFound";
import { useEffect, useState } from "react";
import AdminControls from "./Components/Pages/AdminControls/AdminControls";
import ForgotPassword from "./Components/Pages/ForgotPassword/ForgotPassword";
import Overview from "./Components/Pages/Dashboard/Overview";
import Notifications from "./Components/Pages/UserDetails/Notifications";
import Register from "./Components/Pages/Register/Register";
import { useSelector } from "react-redux";
import { CustomToast } from "./Common/CustomToast/Index";
import { PrivateRoute } from "./Routes/PrivateRoutes";
import { RestrictedRoutes } from "./Routes/RestrictedRoutes";
import PlanBilling from "./Components/Pages/Plans&Billing/PlanBilling";
import Pricing from "./Components/Pages/PricingLandPage/Pricing";
function App() {
  const { success, message, error } = useSelector((state) => state.toast);
  const { token } = useSelector((state) => state?.auth);

  const [theme, settheme] = useState();
  useEffect(() => {
    // let themes = localStorage.getItem("appTheme");
    // settheme(themes || "lightTheme");
    // document.body.classList.add("darkTheme");
    // document.body.classList.remove("lightTheme");
    document.body.classList.add("lightTheme");
  }, []);
  return (
    <>
      <CustomToast success={success} error={error} message={message} />

      <Routes>
        <Route path="/" element={<RestrictedRoutes />}>
          <Route index path="/pricing" element={<Pricing />} />

          <Route index path="/" element={<Login />} />
          <Route index path="/register" element={<Register />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route index path="overview" element={<Overview />} />
          <Route index path="notifications" element={<Notifications />} />
          <Route index path="adminControls" element={<AdminControls />} />
          <Route index path="/plan/billing" element={<PlanBilling />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
