import React, { useEffect } from "react";
import "antd/dist/antd.css";
import "./PriceStyle.css";
import { Button, Card, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { plansService } from "../../../Utilities/Redux/Services/PlansOptions.service";
import { useNavigate } from "react-router-dom";

export default function Pricing() {
  const { count, rows } = useSelector((data) => data?.plansListData?.plansList);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(plansService(""));
  }, []);

  const handleNavigateToRegister = () => {
    navigate("/register");
  };

  function formatCash(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
  }
  return (
    <>
      <h2 className="heading">Pricing</h2>
      {rows?.length > 0 ? (
        <>
          <div className="App">
            {rows?.map((items) => {
              return (
                <>
                  <Card
                    // loading={true}
                    className="module-border-wrap"
                    id="scale-up-1"
                    hoverable
                    style={{
                      width: 381,
                      marginLeft: "8px",
                      padding: "8px",
                      height: 400,
                      boxShadow: "1px 4px 8px rgba(0,0,0,0.1)",
                    }}
                    cover={
                      <div style={{ paddingBottom: 24 }}>
                        <p
                          style={{
                            fontSize: 20,
                            margin: 0,
                            fontSize: "1rem",
                            fontWeight: 700,
                          }}
                        >
                          {items?.title}
                        </p>
                        <p style={{ margin: 0, fontSize: "4rem" }}>
                          {/* <span style={{ fontSize: 55 }}>{items?.price}</span>/mo */}
                          {items?.price == 0 ? "FREE" : `$${items?.price}`}
                          <span style={{ color: "#313c47", fontSize: "30%" }}>
                            {items?.price !== 0 && "/mo"}
                          </span>
                        </p>
                      </div>
                    }
                  >
                    <div style={{ textAlign: "left" }}>
                      <p style={{ padding: 10, color: "grey" }}>
                        {items?.description}
                      </p>

                      <button
                        className="selectedBtn"
                        onClick={handleNavigateToRegister}
                      >
                        {items?.title == "Basic" || items?.title == "Silver"
                          ? "GET FREE API KEY"
                          : "GET STARTED NOW"}
                      </button>
                      <p style={{ padding: 10, color: "gray" }}>
                        {`${formatCash(items?.monthly_hits)} credits /mo`}
                      </p>
                    </div>
                  </Card>
                </>
              );
            })}
          </div>
        </>
      ) : (
        <p style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.25)" }}>
          No Data
        </p>
      )}
    </>
  );
}
