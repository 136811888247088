import { createSlice } from "@reduxjs/toolkit";
import { regeneratekey } from "../Services/SecurityKey.Service";

const initialState = {
  securityKeyValue: null,
  loading: false,
  error: false,
};

const securityKeySlice = createSlice({
  name: "securityKey-slice",
  initialState,
  reducers: {},
  extraReducers: {
    [regeneratekey.pending]: (state, action) => {
      state.loading = true;
    },
    [regeneratekey.fulfilled]: (state, action) => {
      state.securityKeyValue = action?.payload?.vender?.security_key;
      state.loading = false;
    },

    [regeneratekey.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {} = securityKeySlice.actions;
export default securityKeySlice.reducer;
