import { Breadcrumb, Col, Row } from "antd";
import React from "react";
import "./AdminControls.scss";

import AccountDetails from "../../SettingsComponents/ AccountDetails/AccountDetails";
import ChangePassword from "../../SettingsComponents/ChangePassword/ChangePassword";
import UpdateEmailAddress from "../../SettingsComponents/UpdateEmailAddress/UpdateEmailAddress";

function AdminControls() {
  return (
    <>
      <div className="breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>Settings</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="adminControls commonPadding">
        <Row gutter={[30, { xs: 8, sm: 16, md: 16, lg: 16 }]}>
          <Col xs={24} xl={12}>
            <div className="account-detail  card-outer">
              <AccountDetails />
            </div>
          </Col>
          <Col xs={24} xl={12}>
            <ChangePassword />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AdminControls;
