import React from "react";
import { Table } from "antd";

const columns = [
  {
    title: "Last 100 API Request",
    dataIndex: "lastrequest",
    key: "lastrequest",
    render: (text, record) => {
      return (
        <div>
          {!record?.status ? (
            <>
              <span style={{ color: "red" }}>HTTP 400 </span>
              <span style={{ color: "GrayText" }}>
                {`- IP ${record?.lastLoginIp}`}
              </span>
            </>
          ) : (
            <>
              <span style={{ color: "green" }}>HTTP 200 </span>
              <span style={{ color: "GrayText" }}>
                {`- IP ${record?.lastLoginIp}`}
              </span>
            </>
          )}

          <br />
          <div>{record?.url}</div>
        </div>
      );
    },
  },
  {
    title: <span className="timestamp">Timestamp</span>,
    dataIndex: "timestamp",
    key: "timestamp",
    render: (text, record) => {
      return (
        <>
          <span>{new Date(record?.created_at).toLocaleDateString()}</span>,{" "}
          <span>{new Date(record?.created_at).toLocaleTimeString()}</span>
        </>
      );
    },
  },
  {
    title: "Credit Count",
    dataIndex: "creditcount",
    key: "creditcount",
    render: (text, record) => {
      return record?.status == false ? 0 : record?.credit_count;
    },
  },
];

const OverviewTable = ({ apiListdata , className , scroll}) => {
  return (
    <Table
      columns={columns}
      dataSource={apiListdata}
      pagination={false}
      scroll={scroll}
      className={`commontable ${className}`}
    />
  );
};

export default OverviewTable;
