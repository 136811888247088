import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loginSlice from "./Slices/Login.Slice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import RegisterSlice from "./Slices/Register.Slice";
import UsersSlice from "./Slices/Users.Slice";
import ApiListSlice from "./Slices/ApiList.Slice";
import CommonToastSlice from "./Slices/CommonToast.Slice";
import NotificationsSlice from "./Slices/NotificationsStatus.Slice";
import securityKeySlice from "./Slices/SecurityKey.Slice";
import NotificationListSlice from "./Slices/NotificationList.Slice";
import ActiveIndexSlice from "./Slices/ActivesIndex.Slice";
import plansOptionsSlice from "./Slices/plansOptions.slice";
import CurrentPlansSlice from "./Slices/CurrentPlans.slice";
const persistConfig = {
  key: "root",
  storage,
  // whitelist: ["app"],  // mtlb app comonent nu ee presist kre
  // blacklist:["app"] , // mtlb ehnu shadke baaki sbnu presist kre
};
const appReducer = combineReducers({
  auth: loginSlice,
  register: RegisterSlice,
  users: UsersSlice,
  apiList: ApiListSlice,
  toast: CommonToastSlice,
  notification: NotificationsSlice,
  secureKey: securityKeySlice,
  notificationdata: NotificationListSlice,
  activeIndex: ActiveIndexSlice,
  plansListData: plansOptionsSlice,
  currentPlanData:CurrentPlansSlice
});

const rootReducer = (state, action) => {
  if (action.type === "CLEAR_STATE") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
