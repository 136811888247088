import { createSlice } from "@reduxjs/toolkit";
import { loginService } from "../Services/Login.Services";
const initialState = {
  token: null,
  loading: false,
  error: false,
};

const loginSlice = createSlice({
  name: "login-slice",
  initialState,
  reducers: {
    addToken: (state, action) => {
      state.token = action.payload;
    },
    clearToken: (state, action) => {
      state.token = null;
    },
  },
  extraReducers: {
    [loginService.pending]: (state, action) => {
      state.loading = true;
    },
    [loginService.fulfilled]: (state, action) => {
      state.token = action?.payload?.auth;
      state.loading = false;
    },

    [loginService.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { addToken, clearToken } = loginSlice.actions;
export default loginSlice.reducer;
