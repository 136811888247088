import { Button, Select } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getSelectedPlanService } from "../../../Utilities/Redux/Services/PlansOptions.service";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const selectStyle = {
  width: "100%",
  maxWidth: "600px",
  minWidth: "250px",
  borderRadius: "10px",
};

const selectButton = {
  width: "100%",
  maxWidth: "600px",
  minWidth: "250px",
  marginTop: "15px",
  height: "45px",
  borderRadius: "6px",
};
function UpgradePlan({ currentPlanInfo, setCurrentPlanInfo, setUpdatedPlan }) {
  const dispatch = useDispatch();

  const [newSelectedPlan, setNewSelectedPlan] = useState([]);
  const currentPlan = useSelector(
    (state) => state?.currentPlanData?.currnetPlan
  );
  const { count, rows } = useSelector(
    (state) => state?.currentPlanData?.newPlanLis
  );
  useEffect(() => {
    setCurrentPlanInfo({
      value: currentPlan?.plan_title,
      label: `${currentPlan?.plan_title} - ${
        currentPlan?.description ? currentPlan?.description : ""
      }`,
    });
  }, [currentPlan]);
  

  useEffect(() => {
    dispatch(getSelectedPlanService());
  }, []);

  useEffect(() => {
    const updatedPlansList = rows?.map((list, index) => {
      return {
        label: `${list?.title} - ${list?.description}`,
        value: list?.title,
      };
    });
    setNewSelectedPlan(updatedPlansList);
  }, [rows]);

  const handleSelectedPlan = (e, item) => {
    setCurrentPlanInfo(item);
  };

  const handleUpdatePlan = () => {
    const data = rows?.find((item) => {
      return item?.title == currentPlanInfo?.value && item?.price;
    });

    setUpdatedPlan(data);
  };
  return (
    <div style={{ marginTop: "15px" }}>
      <div>
        {/* <p> Use the dropdown below to upgrade your plan</p> */}
        <p>
          Crypto Apis end points :{" "}
          <a
            href="https://cmc-api.staging-host.com/v1/api-docs/"
            target="_blank"
          >
            {" "}
            https://cmc-api.staging-host.com/v1/api-docs/
          </a>
        </p>

        <Select
          onChange={(e, item) => handleSelectedPlan(e, item)}
          style={selectStyle}
          value={currentPlanInfo}
          options={newSelectedPlan}
        />

        {/* <Button
          type="primary"
          style={selectButton}
          onClick={() => handleUpdatePlan()}
        >
          Upgrade Your Plan
        </Button> */}
      </div>
      {/* By upgrading you consent to the <Link to={""}> Term of Use</Link> for the
      new plan
      <p>
        view the <Link to={""}>API Plan Feature Comparision</Link>
      </p> */}
    </div>
  );
}

export default UpgradePlan;
