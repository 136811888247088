
import { Breadcrumb, Card, Col, Row, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KeyOutlined, PieChartOutlined } from "@ant-design/icons";

import "./Overview.scss";
import CountdownTimer from "../../Ui/CoutdownTimer/CountdownTimer";

import CustomCard from "../../Ui/Card/CustomCard";
import OverviewTable from "./OverviewTable";
import { apiListService } from "../../../Utilities/Redux/Services/Api.Service";
import { regeneratekey } from "../../../Utilities/Redux/Services/SecurityKey.Service";
import { setToast } from "../../../Utilities/Redux/Slices/CommonToast.Slice";
import { usersService } from "../../../Utilities/Redux/Services/Users.Service";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
let secretKey = "";

const { Text } = Typography;

export const getSecretKey = (key) => {
   secretKey = key;
   return key;
};
function Overview() {
   const dispatch = useDispatch();
   const apiListdata = useSelector((state) => state?.apiList?.apliListRecord);
   const { securityKeyValue } = useSelector((state) => state?.secureKey);

   const state = useSelector((state) => state?.users?.users);

   const dailyHits = state?.vender?.api_hits_plan?.daily_hits;
   const monthlyHits = state?.vender?.api_hits_plan?.monthly_hits;

   const todayCreditCount = state?.usage?.todayCreditCount;
   const yesterdayCreditCount = state?.usage?.yesterdayCreditCount;
   const currentMonth = state?.usage?.currentMonth;
   const lastMonth = state?.usage?.lastMonth;

   const todayCreditCountPercentage = (todayCreditCount * 100) / dailyHits;
   const yesterdayCreditCountPercentage = (yesterdayCreditCount * 100) / dailyHits;
   const CurrentMonthCountPercentage = (currentMonth * 100) / monthlyHits;
   const lastMonthCountPercentage = (lastMonth * 100) / monthlyHits;

   const [showButton, setShowButton] = useState(false);
   const [showSecretText, setShowSecretText] = useState(false);

   const handleMouseEnter = () => {
      setShowButton(true);
   };
   const geneRayeKeyFn = () => {
      dispatch(regeneratekey());
   };
   const handleMouseLeave = () => {
      setShowButton(false);
   };

   useEffect(() => {
      dispatch(apiListService());
   }, []);

   useEffect(() => {
      dispatch(usersService());
   }, []);

   const copyKeyValue = () => {
      const el = document.createElement("textarea");
      el.value = secretKey;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      // }

      if (secretKey) {
         dispatch(
            setToast({
               message: "Coppied !!",
               success: true,
               error: false,
            })
         );
      }
   };

   return (
      <>
         <div className="breadcrumb">
            <Breadcrumb>
               <Breadcrumb.Item>Overview</Breadcrumb.Item>
            </Breadcrumb>
         </div>
         <div className="commonPadding">
            <section className="overviewdata">
               <Row justify="space-between" gutter={[30, 30]}>
                  <Col xs={24} lg={10}>
                     <div className="overviewdata__ApiKey card-outer">
                        <CustomCard
                           icon={
                              <KeyOutlined
                                 style={{ color: "grey", marginRight: "4px" }}
                              />
                           }
                           text="API Key"
                        // onMouseEnter={handleMouseEnter}
                        // onMouseLeave={handleMouseLeave}
                        >
                           <div className="textareasection">
                              <div
                                 onMouseLeave={handleMouseLeave}
                                 onMouseEnter={handleMouseEnter}
                                 className="cardgenerate"
                                 style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                 }}
                              >
                                 <Text className="textarea">
                                    {showSecretText ? (
                                       <div className="staradd">
                                          <p style={{ fontSize: "20px", minHeight: "80px", }} >
                                             {secretKey}
                                          </p>
                                       </div>
                                    ) : (
                                       <div className="star">
                                          <p style={{ fontSize: "20px", minHeight: "80px" }}>
                                             *****_******__*************************************_******
                                          </p>
                                       </div>
                                    )}
                                 </Text>

                                 <button
                                    onMouseLeave={() => setShowSecretText(false)}
                                    onMouseEnter={() => setShowSecretText(true)}
                                    className="copyKeybtn"
                                 >
                                    <p onClick={copyKeyValue}> Copy Key</p>
                                 </button>
                              </div>

                              <div className="regeneratekey" onClick={geneRayeKeyFn}>
                                 Regenerate Key
                              </div>
                           </div>
                        </CustomCard>
                     </div>
                  </Col>
                  <Col xs={24} lg={14}>
                     <div className="overviewdata__right card-outer">
                        <CustomCard
                           icon={<PieChartOutlined style={{ color: "grey" }} />}
                           text=" API Key Usage"
                           buttonshows={true}
                           small
                           extra={
                              <ButtonCustom
                                 lightgray
                                 large
                                 label={state?.vender?.api_hits_plan?.title.toUpperCase() || ""}
                                 style={{ fontWeight: 700 }}
                              />
                           }
                        >
                           <Row
                              style={{ padding: "20px" }}
                              justify="center"
                              gutter={[30, 15]}
                           >
                              <Col md={12} xl={6}>
                                 <CountdownTimer
                                    title="Credits Today"
                                    counts={todayCreditCount}
                                    color={"#01a9fb"}
                                    creditCountPercentage={todayCreditCountPercentage}
                                 />
                              </Col>
                              <Col md={12} xl={6}>
                                 <CountdownTimer
                                    title="Credits Yesterday"
                                    counts={yesterdayCreditCount}
                                    color={"#218380"}
                                    creditCountPercentage={yesterdayCreditCountPercentage}
                                 />
                              </Col>
                              <Col md={12} xl={6}>
                                 <CountdownTimer
                                    title="Credits This Month"
                                    counts={`${currentMonth} / ${monthlyHits || 0}`}
                                    color={"#E7F011"}
                                    creditCountPercentage={CurrentMonthCountPercentage}
                                 />
                              </Col>
                              <Col md={12} xl={6}>
                                 <CountdownTimer
                                    title="Credits Last Month"
                                    counts={`${lastMonth} / ${monthlyHits || 0}`}
                                    color={"#F0111B"}
                                    creditCountPercentage={lastMonthCountPercentage}
                                 />
                              </Col>
                           </Row>
                           <p className="greytext">
                              Last Updated: Today at {
                                 ' ' + new Date().toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                 })
                              }
                           </p>
                        </CustomCard>
                     </div>
                  </Col>
               </Row>
            </section>

            <section className="tablesection card-outer ">
               <p className="headingsection"> API Request Log</p>
               <OverviewTable
                  scroll={{ y: "calc(100vh - 2px)" }}
                  apiListdata={apiListdata}
               />
            </section>
         </div>
      </>
   );
}

export default Overview;
