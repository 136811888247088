import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { Layout, Menu, Grid } from "antd";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { BellOutlined, AppstoreOutlined } from "@ant-design/icons";
import "./DashboardLayout.scss";
import useWindowDimensions from "../../Ui/useWindowDimensions/useWindowDimensions.jsx";
import { setActiveIndex } from "../../../Utilities/Redux/Slices/ActivesIndex.Slice";
import { useDispatch, useSelector } from "react-redux";
import { navigationItems } from "../../../Common/Constant/NavigationItems";

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

function DashboardLayout(props) {
  const [collapsed, setCollapsed] = useState(false);
  const { Content, Sider } = Layout;
  const themes = localStorage.getItem("appTheme");
  const width = useWindowDimensions();
  const dispatch = useDispatch();
  const { activeIndex } = useSelector((state) => state?.activeIndex);
  const [theme, setTheme] = useState();
  useEffect(() => {
    if (themes) {
      setTheme(themes);
    }
  }, [themes]);

  const changeTheme = (data) => {
    setTheme(data);
  };
  const items = [
    getItem(
      <Link to="/overview">Overview</Link>,
      "1",
      <span>
        <AppstoreOutlined
          style={{ color: "#18a2dc", fontSize: 20, fontWeight: 600 }}
        />
      </span>
    ),
    getItem(
      <Link to="/notifications">Notifications</Link>,
      "2",
      <span className="anticon anticon-desktop ant-menu-item-icon">
        <BellOutlined
          style={{ color: "#18a2dc", fontSize: 20, fontWeight: 600 }}
        />
      </span>
    ),
  ];

  const { useBreakpoint } = Grid;
  return (
    <>
      <Header changeTheme={changeTheme} />
      <Layout className={width < 991 ? "dashboardSidebarOuter" : ""}>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(value) => setCollapsed(value)}
        >
          <Menu
            theme="dark"
            selectedKeys={[activeIndex]}
            mode="inline"
            items={navigationItems?.map((item, index) => {
              return {
                key: item?.key,
                label: (
                  <p onClick={() => dispatch(setActiveIndex(item?.key))}>
                    <Link to={item?.route}>{item?.label}</Link>
                  </p>
                ),
                icon: item?.icon,
              };
            })}
          />
        </Sider>
        <Content className="contentDashboard">
          <Outlet context={theme} />
        </Content>
      </Layout>
      <Footer />
    </>
  );
}

export default DashboardLayout;
