import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../Api/Index";
import { API_BASE_URL } from "../../../Common/Constant/Index";
import { setToast } from "../Slices/CommonToast.Slice";
import { setJwtExpireError } from "../../../Common/CommonFuntion";

export const plansService = createAsyncThunk(
  "plans-for-select",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const token = getState().auth.token;
    try {
      let url = `https://cmc-api.staging-host.com/api/admin/key/plans/list`;
      let res = await API.get(payload)(url, payload);
      return { plans: res?.data };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getSelectedPlanService = createAsyncThunk(
  "selected-plan",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const token = getState().auth.token;
    try {
      let url = `${API_BASE_URL}apiKey/plans/billing`;
      let res = await API.get(token)(url, payload);
      return {
        currentPlanList: res?.data?.currentplan,
        newPlanList: res?.data?.planList,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updatePlanService = createAsyncThunk(
  "update-plan",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const token = getState().auth.token;
    try {
      let url = `${API_BASE_URL}apiKey/plans/billing//`;
      let res = await API.put(token)(url, payload);

      dispatch(
        setToast({
          message: res?.message,
          success: true,
          error: false,
        })
      );
      (await res?.status) && dispatch(getSelectedPlanService(token));
    } catch (e) {
      dispatch(
        setToast({
          message: e?.message,
          success: false,
          error: true,
        })
      );
      if ((e.message = "Unauthorize Request")) {
        setJwtExpireError(dispatch, e);
      }
      return rejectWithValue(e);
    }
  }
);
