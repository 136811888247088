import { Breadcrumb, Card, Tree } from "antd";
import React, { useEffect, useState } from "react";
import CustomCard from "../../Ui/Card/CustomCard";
import "./Notifications.scss";
// import CheckboxCustom from "../../Ui/checkbox/CheckboxCustom.jsx";
import NotificationTable from "./NotificationTable";
import { useDispatch, useSelector } from "react-redux";
// import { updateNotificationSettings } from "../../../Utilities/Redux/Services/NotificationsStatus.Service";
import { notificatiosnListService } from "../../../Utilities/Redux/Services/NotificationList.Service";
import { BellOutlined, SettingOutlined } from "@ant-design/icons";
function Notifications() {
  const data = useSelector(
    (state) => state?.notificationdata?.getNotificationList
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(notificatiosnListService());
  }, []);

  // function updateStatusFn(item, e) {
  //   dispatch(
  //     updateNotificationSettings({
  //       notification_type_id: item?.id,
  //       status: e.target.checked ? "active" : "inactive",
  //     })
  //   );
  // }

  const alertNotification = data?.alertNotification;

  const checkedKeysList = [];

  const childrenData = Object.keys(alertNotification).filter(key => alertNotification[key].show_user).map(key => {
    if (alertNotification[key].allow) {
      checkedKeysList.push(key);
    }
    return {
      key: key,
      title: alertNotification[key].title
    };
  });

  const treeData = [{
    title: 'Notify me by email',
    key: 'notify_me_by_email',
    children: childrenData,
  }];

  return (
    <>
      <div className="breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>Notifications</Breadcrumb.Item>
          {/* <Breadcrumb.Item>
          <Link to="">Application Center</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="">Application List</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>An Application</Breadcrumb.Item> */}
        </Breadcrumb>
      </div>
      <div className="commonPadding">
        <section className="notification card-outer tablesection">
          <CustomCard
            icon={
              <BellOutlined style={{ marginRight: "4px", color: "grey" }} />
            }
            text="Latest Notifications"
          >
            <NotificationTable notificationData={data?.NotificationList} />
          </CustomCard>
        </section>
        <div
          style={{ marginTop: "30px" }}
          className="notificationsetting card-outer "
        >

          <Card title="User notification alert settings" >
            <h3> What plan usage alerts would you like to receive? </h3>
            <Tree checkable
              // onSelect={onSelect}
              // onCheck={EditSettingFromSubmit}
              treeData={treeData}
              defaultExpandedKeys={['notify_me_by_email']}
            checkedKeys={checkedKeysList}
            />
          </Card>

          {/* <CustomCard
            icon={
              <SettingOutlined style={{ marginRight: "4px", color: "grey" }} />
            }
            text="Notification Settings"
          >
            <div style={{ padding: "30px" }} className="notificationinnerText">
              <p>What plan usage alerts would you like to receive?</p>
              <div style={{ marginTop: "10px" }} className="checkboxes">
                {data?.NotificationPlan?.map((item) => {
                  return (
                    <CheckboxCustom
                      style={{ marginTop: "6px" }}
                      checked={
                        item?.notificationSettingData?.status == "active"
                      }
                      name={"notify_hits_75"}
                      onChange={(e) => updateStatusFn(item, e)}
                      checkboxTxt={item?.value}
                      checkbox
                      className="notificationcheck"
                    />
                  );
                })}
              </div>
            </div>
          </CustomCard> */}
        </div>
      </div>
    </>
  );
}

export default Notifications;
