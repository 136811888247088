import { createSlice } from "@reduxjs/toolkit";
import { notificatiosnListService } from "../Services/NotificationList.Service";

const initialState = {
  getNotificationList: [],
  loading: false,
  error: false,
};

const notificationListSlice = createSlice({
  name: "notificationList-slice",
  initialState,
  reducers: {},
  extraReducers: {
    [notificatiosnListService.pending]: (state, action) => {
      state.loading = true;
    },
    [notificatiosnListService.fulfilled]: (state, action) => {
      state.getNotificationList = action?.payload?.notificationList;
      state.loading = false;
    },

    [notificatiosnListService.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {} = notificationListSlice.actions;
export default notificationListSlice.reducer;
