import React from "react";
import "./Footer.scss";

import { CopyrightOutlined } from "@ant-design/icons";
function Footer() {
  return (
    <div className="footer">
      <p className="footer__text">
        <span className="footer_flexStyle">
          <span>
            <span className="footer__text__copyrightIcon">
              <CopyrightOutlined />
            </span>
            2022 - 2024 CoinZone All rights reserved{" "}
          </span>
          <span className="footer__text__spanText">Cookie Preferences</span>
        </span>
      </p>
    </div>
  );
}

export default Footer;
