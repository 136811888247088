import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import DashboardLayout from "../Components/Layout/DashboardLayout/DashboardLayout";

export function PrivateRoute() {
  const { token } = useSelector((state) => state?.auth);

  return token !== null ? (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  ) : (
    <Navigate to="/" />
  );
}
