import { createSlice } from "@reduxjs/toolkit";
import {
  getSelectedPlanService,
  plansService,
} from "../Services/PlansOptions.service";

const initialState = {
  plansList: [],
  loading: false,
  error: false,
};

const plansSlice = createSlice({
  name: "plans-slice",
  initialState,
  reducers: {
    setCurrentPlanData: (state, action) => {
      state.currnetPlan = action?.payload;
    },
  },
  extraReducers: {
    [plansService.pending]: (state, action) => {
      state.loading = true;
    },
    [plansService.fulfilled]: (state, action) => {
      state.plansList = action?.payload?.plans;
      state.loading = false;
    },

    [plansService.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { setCurrentPlanData } = plansSlice.actions;
export default plansSlice.reducer;
