import { Empty } from "antd";
import React from "react";
import './PageNotFound.scss'
function PageNotFound() {
  return (
    <div className="pagenotfound">
      <Empty
        description={
          <span>
            Page Not Found
          </span>
        }
      />
    </div>
  );
}

export default PageNotFound;
