import * as yup from "yup";
// const phoneRegExp =
// /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const LOGIN_VALIDATION = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    ),
  password: yup.string().required("Password is required"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   "Password should be a minimum of 8 characters including number, upper, lower and one special character"
  // ),
});

const REGISTERATION_VALIDATION = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .matches(/^(?![\s])[a-zA-Z0-9\s]+$/, "Please enter a valid name.")
    .max(30, "Name must not exceed 30 characters"),

  phone: yup
    .object({
      phone: yup.string().required("Phone number is required").max(20),
    })
    .required("Phone is required"),

  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    ),

  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password should be a minimum of 8 characters including number, upper, lower, and one special character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf(
      [yup.ref("password")],
      "Confirm password and Password should be same"
    ),
});
const FORGOT_VALIDATION = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    ),
});

const ACCOUNT_DETAILS_VALIDATION = yup.object().shape({
  // firstName: yup.string().required("First name is required"),
  // lastName: yup.string().required("Last name is required"),
  // middleName: yup.string().required("Middle name is required"),

  name: yup
    .string()
    .required("Name is required")
    .matches(/^(?![\s])[a-zA-Z\s]+$/, "Please enter valid name.")
    .max(30, "Name must not exceed 30 characters"),

  // phone: yup
  //   .string()

  //   .required("Phone is required")
  //   .matches(/^[0-9]\d{9}$/, {
  //     message: "Please enter valid number.",
  //     excludeEmptyString: false,
  //   }),
  phone: yup.string().required("Phone number is required").max(20),
});

export const CHANGE_PASSWORD_VALIDATION = yup.object().shape({
  oldPassword: yup.string().required(" Old password is required"),
  newPassword: yup
    .string()
    .required("New password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      " New password should be a minimum of 8 characters including number, upper, lower and one special character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf(
      [yup.ref("newPassword")],
      "Confirm password and New password should be same"
    ),
});

const UPDATE_EMAIL_VALIDATION = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    ),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password should be a minimum of 8 characters including number, upper, lower and one special character"
    ),
});

export {
  LOGIN_VALIDATION,
  REGISTERATION_VALIDATION,
  FORGOT_VALIDATION,
  ACCOUNT_DETAILS_VALIDATION,
  UPDATE_EMAIL_VALIDATION,
};
