import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Mainlayout from "../Components/Layout/Mainlayout/Mainlayout";
import { useEffect } from "react";
import { setActiveIndex } from "../Utilities/Redux/Slices/ActivesIndex.Slice";

export function RestrictedRoutes() {
  const { token } = useSelector((state) => state?.auth);
  const location = useLocation();
  const routes = ["/overview", "/notifications", "/adminControls"];
  const dispatch = useDispatch();
  useEffect(() => {
    let resulte = routes.includes(location.pathname);
    if (!resulte) {
      dispatch(setActiveIndex("1"));
    }
  }, [location.pathname]);

  return token == null ? (
    <Mainlayout>
      <Outlet />
    </Mainlayout>
  ) : (
    <Navigate to="/overview" />
  );
}
