import { Button } from "antd";
import React from "react";
import "./ButtonCustomStyle.scss";

const ButtonCustom = (props) => {
  const {
    garyBtn,
    lightBtn,
    lightgray,
    white,
    yellowBtn,
    lessPadding,
    leftIcon,
    label,
    small,
    onlyIcon,
    rightIcon,
    medium,
    large,
    black,
    customClass,
    lytgaryBtn,
    orderBtn,
    garyBtns,
    type,
  } = props;
  return (
    <Button
      className={`btnCustom ${garyBtn ? "garyBtn" : ""} ${
        lightgray ? "lightgray" : ""
      } ${garyBtns ? "garyBtns" : ""} ${small ? "small" : ""} ${
        lytgaryBtn ? "lytgaryBtn" : ""
      } ${lightBtn ? "lightBtn" : ""}${yellowBtn ? "yellowBtn" : ""}  ${
        orderBtn ? "orderBtn" : ""
      } ${lessPadding ? "lessPadding" : ""} ${medium ? "medium" : ""}${
        black ? "black" : ""
      } ${white ? "white" : ""} ${large ? "large" : ""} ${customClass}`}
      {...props}
      // type={type}
      htmlType={type}
    >
      {leftIcon && <span className="leftIcon">{leftIcon}</span>}
      {label && label}
      {onlyIcon && <span className="onlyIcon">{onlyIcon}</span>}
      {rightIcon && <span className="rightIcon">{rightIcon}</span>}
    </Button>
  );
};

export { ButtonCustom };
