import { setToast } from "../Utilities/Redux/Slices/CommonToast.Slice";

export const setJwtExpireError = (dispatch, e) => {
  dispatch(
    setToast({
      message: e?.message,
      success: false,
      error: true,
    })
  );

  setTimeout(() => {
    dispatch({ type: "CLEAR_STATE" });
  }, 2000);
};
