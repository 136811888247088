import React, { useEffect, useState } from "react";
import "../Login/Login.scss";
import { Col, Row } from "antd";
import InputCustom from "../../Ui/input/InputCustom.jsx";
import { ButtonCustom } from "../../Ui/button/ButtonCustom.jsx";
import { Link, useNavigate } from "react-router-dom";
import LoginDetail from "../../Layout/LoginDetail/LoginDetail";
import AntierLogo from "../../Assets/AdminImages/AntierLogo.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as COMMON_FUNCTION from "../../../Common/formValidations";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { registerService } from "../../../Utilities/Redux/Services/Register.Service";

import PlanOptions from "./PlanOptions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./Register.css";

function Register() {
  const [plansLis, setPlansList] = useState([]);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
  const { success, message, error } = useSelector((state) => state.toast);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(COMMON_FUNCTION.REGISTERATION_VALIDATION),
  });

  const onSubmit = (data) => {
    const justPhoneNumber = data?.phone?.phone.replace(data?.phone?.code, "");
    const phoneNumber = `${data?.phone?.code} ${justPhoneNumber}`;
    dispatch(
      registerService({
        name: data.name,
        email: data.email,
        contact_no: phoneNumber,
        password: data.password
        //planTitle :selectedDropdownValue?.value    //  when un-comment it when you will also un-comment line numb 174
      })
    );
  };

  useEffect(() => {
    if (success) {
      reset();
      navigate("/");
    }
  }, [success]);
  return (
    <div className="flexAuto">
      <div className="loginScreen">
        <div className="container">
          <div>
            <Row>
              <Col xs={24} md={12} className="loginScreen__leftCol">
                <LoginDetail
                  topimage={
                    <span>
                      <img src={AntierLogo} width={300} alt="Antier Logo" />
                    </span>
                  }
                />
              </Col>
              <Col xs={24} md={12} className="loginScreen__rytCol">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="loginScreen__loginLeft">
                    <div className="loginScreen__logInContact">
                      <h2>CoinZone Account Register </h2>
                    </div>
                    <div className="loginScreen__inputSec">
                      <div className="loginScreen__innerInput">
                        <Controller
                          name="name"
                          control={control}
                          render={({ field }) => (
                            <InputCustom
                              basicInput
                              inputCustum
                              label="Name"
                              {...field}
                            />
                          )}
                        />
                        {errors?.name?.message !== "" && (
                          <p style={{ color: "red" }}>
                            {errors?.name?.message}
                          </p>
                        )}
                      </div>
                      <div className="loginScreen__innerInput">
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <InputCustom
                              basicInput
                              inputCustum
                              label="Email"
                              {...field}
                            />
                          )}
                        />
                        {errors?.email?.message !== "" && (
                          <p style={{ color: "red" }}>
                            {errors?.email?.message}
                          </p>
                        )}
                      </div>

                      <label>Phone</label>

                      <div className="loginScreen__innerInput">
                        <Controller
                          name="phone"
                          control={control}
                          render={({ field }) => (
                            <PhoneInput
                              style={{
                                marginTop: "8px",
                              }}
                              country={"in"}
                              basicInput
                              inputCustum
                              placeholder="Pleas enter country code and phone number"
                              onChange={(e, dialCode) => {
                                const phoneDetails = {
                                  code: dialCode?.dialCode, // Update this line to include the country code
                                  phone: e,
                                };
                                field.onChange(phoneDetails);
                              }}
                            />
                          )}
                        />

                        {errors?.phone?.phone?.message !== "" && (
                          <p style={{ color: "red" }}>
                            {errors?.phone?.phone?.message}
                          </p>
                        )}
                      </div>

                      {/* </div> */}

                      <div className="loginScreen__innerInput">
                        <Controller
                          name="password"
                          control={control}
                          render={({ field }) => (
                            <InputCustom
                              passwordInput
                              inputCustum
                              eyeIcon
                              label="Password"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e.target.value.trim());
                              }}
                            />
                          )}
                        />
                        {errors?.password?.message !== "" && (
                          <p style={{ color: "red" }}>
                            {errors?.password?.message}
                          </p>
                        )}
                      </div>
                      {/* <PlanOptions
                        setSelectedDropdownValue={setSelectedDropdownValue}
                        selectedDropdownValue={selectedDropdownValue}
                      /> */}

                      <div className="loginScreen__innerInput">
                        <Controller
                          name="confirmPassword"
                          control={control}
                          render={({ field }) => (
                            <InputCustom
                              passwordInput
                              inputCustum
                              eyeIcon
                              label="Confirm Password"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                              }}
                            />
                          )}
                        />
                        {errors?.confirmPassword?.message !== "" && (
                          <p style={{ color: "red" }}>
                            {errors?.confirmPassword?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "20px" }}
                      className="loginScreen__logRegBtn"
                    >
                      <ButtonCustom
                        label="Submit"
                        yellowBtn
                        large
                        type="submit"
                      />
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
