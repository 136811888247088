import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../Api/Index";
import { setToast } from "../Slices/CommonToast.Slice";
import { API_BASE_URL } from "../../../Common/Constant/Index";
import { setJwtExpireError } from "../../../Common/CommonFuntion";

export const loginService = createAsyncThunk(
  "login-service",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `${API_BASE_URL}auth/login`;
      let res = await API.post(payload)(url, payload);
      dispatch(
        setToast({
          message: res?.message,
          success: true,
          error: false,
        })
      );
      return { auth: res?.data?.token, status: true };
    } catch (e) {
      if (e?.code == 500) {
        dispatch(
          setToast({
            message: "Server Error!",
            success: false,
            error: true,
          })
        );
      } else {
        dispatch(
          setToast({
            message: e?.message,
            success: false,
            error: true,
          })
        );
      }

      return rejectWithValue(e);
    }
  }
);
