import { createSlice } from "@reduxjs/toolkit";
import { getSelectedPlanService } from "../Services/PlansOptions.service";

const initialState = {
  currnetPlan: [],
  newPlanLis: [],
};

const currentPlanSlice = createSlice({
  name: "selected-plan-and-new-plan",
  initialState,
  reducers: {},
  extraReducers: {
    [getSelectedPlanService.pending]: (state, action) => {
      state.loading = true;
    },
    [getSelectedPlanService.fulfilled]: (state, action) => {
      state.currnetPlan = action?.payload?.currentPlanList;
      state.newPlanLis = action?.payload?.newPlanList;
      state.loading = false;
    },

    [getSelectedPlanService.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {} = currentPlanSlice.actions;
export default currentPlanSlice.reducer;
