import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../Api/Index";
import { setToast } from "../Slices/CommonToast.Slice";
import { API_BASE_URL } from "../../../Common/Constant/Index";
import { setJwtExpireError } from "../../../Common/CommonFuntion";

export const changePasswordService = createAsyncThunk(
  "changesPassword-service",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const token = getState()?.auth?.token;

    try {
      let url = ` ${API_BASE_URL}account/changePassword`;
      let res = await API.post(token)(url, payload);
      dispatch(
        setToast({
          message: res?.message,
          success: true,
          error: false,
        })
      );
    } catch (e) {
      dispatch(
        setToast({
          message: e?.message,
          success: false,
          error: true,
        })
      );
      if ((e.message = "Unauthorize Request")) {
        setJwtExpireError(dispatch, e);
      }
      return rejectWithValue(e);
    }
  }
);
