import React from "react";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import "./Inputstyle.scss";
function InputCustom(props) {
  const {
    eyeIcon,
    passwordInput,
    basicInput,
    inputInnerTxt,
    innerTxt,
    placeholder,
    inputInnerLargeTxt,
    innerTxtImg,
    innerImg,
    searchImg,
    inputSearch,
    label,
    labelcustom,
    InputCustomStyle,
    fillBg,
    type,
    onChange,
    value
  } = props;
  return (
    <div className="inputLayout ">
      {basicInput ? (
        <>
          {label ? (
            <label className={`label ${labelcustom}`}>{label}</label>
          ) : null}
          <Input
            type={type}
            className={`inputCustum ${inputInnerTxt ? "inputInnerTxt" : ""}${
              inputInnerLargeTxt ? "inputInnerLargeTxt" : ""
            }${inputSearch ? "inputSearch" : ""} ${InputCustomStyle} ${fillBg}`}
            {...props}
            placeholder={placeholder}
            value={value}
            onChange={(e)=>onChange(e.target.value.trimStart())}
          />
        </>
      ) : null}
      {innerTxt ? <span className="innerTxt">{innerTxt}</span> : null}
      {innerTxtImg ? (
        <span className="innerTxtImg">
          {innerTxtImg}
          {innerImg}{" "}
        </span>
      ) : null}
      {searchImg ? <span className="searchImg">{searchImg}</span> : null}
      {passwordInput ? (
        <>
          {label ? <label className="label">{label}</label> : null}
          <Input.Password
            placeholder={placeholder}
            className={`inputCustum ${eyeIcon ? "eyeIcon" : ""}`}
            {...props}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            // onChange={(e)=>onChange(e.target.value.trimStart())}
          />
        </>
      ) : null}
    </div>
  );
}

export default InputCustom;
