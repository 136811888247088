import React from 'react'
import { Layout } from "antd";
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { Outlet } from "react-router-dom";

function Mainlayout() {
    const { Content } = Layout;
  return (
    <>
    <Header changeTheme />
    <Content>
      <Outlet />
    </Content>
    <Footer />
  </>
  )
}

export default Mainlayout