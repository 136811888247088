import { Breadcrumb, Button, Select } from "antd";
import React, { useState } from "react";
import { Card, Col, Row } from "antd";
import UpgradePlan from "./UpgradePlan";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function PlanBilling() {
  const [updatedPlan, setUpdatedPlan] = useState("");
  const [currentPlanInfo, setCurrentPlanInfo] = useState([
    { value: "Basic", label: "Basic - For personal use" },
  ]);
  const currentPlan = useSelector(
    (state) => state?.currentPlanData?.currnetPlan
  );

  const handleReset = () => {
    setCurrentPlanInfo({ value: "Basic", label: "Basic - For personal use" });
    setUpdatedPlan("");
  };

  return (
    <div>
      <div className="breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>Plan and Billing</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div
        style={{
          width: "100%",
          maxWidth: "600px",
          minWidth: "250px",
          background: "grey",
        }}
      >
        <Card title=" Your Current Plan" bordered={true}>
          <h3>{currentPlan?.plan_title} Plan</h3>
          <div>
            <li>
              Monthly credit limit :{" "}
              <span className="plansData">
                {currentPlan?.Monthly_credit_limit}
              </span>
            </li>
            <li>
              Historical data access :{" "}
              <span className="plansData">
                {currentPlan?.Historical_data_access}
              </span>
            </li>
            <li>
              API call rate limmit :{" "}
              <span className="plansData">
                {currentPlan?.API_call_rate_limit}
              </span>
            </li>

            <li>
              Currency conversions :{" "}
              <span className="plansData">
                {currentPlan?.Currency_conversions}
              </span>
            </li>
          </div>
          <UpgradePlan
            setCurrentPlanInfo={setCurrentPlanInfo}
            currentPlanInfo={currentPlanInfo}
            setUpdatedPlan={setUpdatedPlan}
          />

          {/* {updatedPlan && (
            <div>
              <h3>Up to {updatedPlan?.title} plan </h3>
              <Row gutter={30}>
                <Col span={3}>{`$${updatedPlan?.price}/Mo`}</Col>
                <Col span={7}>
                  <Button type="primary">Pay with Paypal</Button>
                </Col>
                <Col span={4}>
                  <Button type="primary" danger onClick={handleReset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </div>
          )} */}
        </Card>
      </div>
    </div>
  );
}

export default PlanBilling;
