import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
let time = 0;

const CountdownTimer = (props) => {
  const getColorFn = () => {
    if (
      Number(props.creditCountPercentage) >= 0 &&
      Number(props.creditCountPercentage) <= 40
    ) {
      return "#01a9fb";
    } else if (
      Number(props.creditCountPercentage) > 40 &&
      Number(props.creditCountPercentage) <= 60
    ) {
      return "#218380";
    } else if (
      Number(props.creditCountPercentage) > 60 &&
      Number(props.creditCountPercentage) <= 80
    ) {
      return "#E7F011";
    } else {
      return "#F0111B";
    }
  };
  ///
  return (
    <div className="notifications-RoundBar">
      <CircularProgressbarWithChildren
        styles={buildStyles({
          textColor: "black",
          pathColor: getColorFn(),
        })}
        value={props.creditCountPercentage}
      >
        {props.counts}
        <div className="notifications-RoundBar_text">{props.title}</div>
      </CircularProgressbarWithChildren>
    </div>
  );
};
export default CountdownTimer;
