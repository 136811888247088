import React, { useEffect, useState } from "react";
import "../SettingsComponents.scss";
import "../../Pages/Login/Login.scss";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import InputCustom from "../../Ui/input/InputCustom";
import CustomCard from "../../Ui/Card/CustomCard";
import User from "../../Assets/Images/User.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as COMMON_FUNCTION from "../../../Common/formValidations";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileService } from "../../../Utilities/Redux/Services/UpdateProfile.Service";
import { usersService } from "../../../Utilities/Redux/Services/Users.Service";
import PhoneInput from "react-phone-input-2";
import "./accountdetail.css";

function AccountDetails() {
  const { success, message, error } = useSelector((state) => state.toast);
  const { users } = useSelector((state) => state);
  const dispatch = useDispatch();

  const {
    register,
    reset,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(COMMON_FUNCTION.ACCOUNT_DETAILS_VALIDATION),
  });

  const onSubmit = (data) => {
    dispatch(
      updateProfileService({
        name: data?.name,
        contact_no: data?.phone,
      })
    );
  };
  useEffect(() => {
    if (success) {
      reset();
    }
    // Update form values when users data is available
    if (users?.users?.getData) {
      const { name, contact_no, email } = users?.users?.getData;
      setValue("name", name);
      setValue("phone", contact_no);
      setValue("email", email);
    }
  }, [success, users]);
  return (
    <>
      <div className="annoucements_block ">
        <CustomCard text="Account Details" src={User}>
          <div className="p-20">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="loginScreen__inputSec">
                <div className="loginScreen__innerInput">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => {
                      return (
                        <InputCustom
                          basicInput
                          inputCustum
                          label="Name"
                          value={getValues("name")} //{field.value} // Use field.value to represent the input value
                          onChange={(e) => setValue("name", e.target.value)} // Use field.onChange
                          {...field}
                        />
                      );
                    }}
                  />
                  {errors?.name?.message !== "" && (
                    <p style={{ color: "red" }}>{errors?.name?.message}</p>
                  )}
                </div>
                <label>Phone</label>

                <div className="loginScreen__innerInput">
                  <Controller
                    name="phone"
                    control={control}
                    render={() => (
                      <PhoneInput
                        style={{
                          marginTop: "6px",
                        }}
                        country={"in"}
                        basicInput
                        inputCustum
                        placeholder="Pleas enter country code and phone number"
                        value={getValues("phone")}
                        onChange={(e, d, v, x) => {
                          let len = d?.dialCode.length;
                          let code = e.toString().substring(0, len);
                          let number = e.toString().substring(len);
                          setValue(
                            "phone",
                            `${code}${number ? ` ${number}` : ""}`
                          );
                          trigger(
                            "phone",
                            `${code}${number ? ` ${number}` : ""}`
                          );
                        }}
                      />
                    )}
                  />
                  {errors?.phone?.message !== "" && (
                    <p style={{ color: "red" }}>{errors?.phone?.message}</p>
                  )}
                </div>

                <div className="loginScreen__innerInput">
                  <Controller
                    name="email"
                    control={control}
                    disabled={true}
                    render={({ field }) => {
                      return (
                        <InputCustom
                          basicInput
                          inputCustum
                          label="Email"
                          {...field}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div>
                <ButtonCustom
                  onClick={() => trigger()}
                  label="Submit"
                  small
                  type="submit"
                />
              </div>
            </form>
          </div>
        </CustomCard>
      </div>
    </>
  );
}

export default AccountDetails;
