import React, { useState } from "react";
import { ButtonCustom } from "../button/ButtonCustom";
import "./CustomCard.scss";

function CustomCard({ text, children, buttonshow, buttonshows, src, icon, extra }) {
  const [showButton, setShowButton] = useState(false);

  const handleMouseEnter = () => {
    setShowButton(true);
  };

  const handleMouseLeave = () => {
    setShowButton(false);
  };

  return (
    <div
      className="card"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="cardInner">
        <div className="heading">
          <p>
            <img src={src} alt="" />
            {icon}
            {text}
          </p>
          {extra}
        </div>
        {children}
      </div>
    </div>
  );
}

export default CustomCard;
