import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  success: false,
  error: false,
  message: "",
};

export const customToastSlice = createSlice({
  name: "toastSlice",
  initialState,
  reducers: {
    setToast: (state, action) => {
      state.success = action.payload.success;
      state.error = action.payload.error;
      state.message = action.payload.message;
    },
    clearToast: (state, action) => {
      state.success = false;
      state.error = false;
      state.message = "";
    },
  },
});

export const { setToast, clearToast } = customToastSlice.actions;

export default customToastSlice.reducer;
