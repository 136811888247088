import { createSlice } from "@reduxjs/toolkit";
import { registerService } from "../Services/Register.Service";
const initialState = {
  token: null,
  loading: false,
  error: false,
};

const registerSlice = createSlice({
  name: "register-slice",
  initialState,
  reducers: {},
  extraReducers: {
    [registerService.pending]: (state, action) => {
      state.loading = true;
    },
    [registerService.fulfilled]: (state, action) => {
      state.token = action?.payload?.auth;
      state.loading = false;
    },

    [registerService.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {} = registerSlice.actions;
export default registerSlice.reducer;
