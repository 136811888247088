import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faCircleCheck,
  faCircleXmark,
  faXmark,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { setToast } from "../../Utilities/Redux/Slices/CommonToast.Slice";
import "./Index.css";

let timeoutId = "";

export function CustomToast({ message, success, error }) {
  const [onfocus, setFocus] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (message !== "") {
      timeoutId = setTimeout(() => {
        clearToast();
      }, 3000);
    }
  }, [message, success, error, onfocus]);

  function clearToast() {
    clearTimeout(timeoutId);
    dispatch(setToast({ message: "", success: false, error: false }));
  }

  return (
    <div>
      {success == true && message !== "" && (
        <div id="snackbarSuccess" className={"show"}>
          <div className="main-container">
            <div className="snak-icon">
              <FontAwesomeIcon size={"2x"} icon={faCircleCheck} color="white" />
            </div>
            <div className="snak-message">
              <p className="message-text">{message}</p>
            </div>
            <div className="close-btn" onClick={() => clearToast()}>
              <FontAwesomeIcon size={"2x"} color="white" icon={faXmark} />
            </div>
          </div>
        </div>
      )}
      {error == true && (
        <>
          {Array.isArray(message) ? (
            <div id="snackbarError" className={"show"}>
              <div className="main-container">
                <div className="snak-icon">
                  <FontAwesomeIcon
                    size={"2x"}
                    icon={faCircleExclamation}
                    color="white"
                  />
                </div>
                <div className="snak-message-error">
                  {Array.isArray(message) &&
                    message?.map((item) => (
                      <>
                        <p className="message-text">
                          {/* {item?.message ? item?.message : null} */}
                          <p className="message-text">{item ? item : null}</p>
                        </p>
                      </>
                    ))}
                </div>
                <div className="close-btn" onClick={() => clearToast()}>
                  {/* CLOSE */}
                  <FontAwesomeIcon size={"2x"} color="white" icon={faXmark} />
                </div>
              </div>
            </div>
          ) : (
            <div id="snackbarError" className={"show"}>
              <div className="main-container">
                <div className="snak-icon">
                  <FontAwesomeIcon
                    size={"2x"}
                    icon={faCircleExclamation}
                    color="white"
                  />
                </div>

                <div className="snak-message-error">
                  <p className="message-text">{message ? message : null}</p>
                </div>
                <div className="close-btn" onClick={() => clearToast()}>
                  <FontAwesomeIcon size={"2x"} color="white" icon={faXmark} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
