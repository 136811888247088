import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../Api/Index";
import { API_BASE_URL } from "../../../Common/Constant/Index";
import { notificatiosnListService } from "./NotificationList.Service";
import { setToast } from "../Slices/CommonToast.Slice";
import { setJwtExpireError } from "../../../Common/CommonFuntion";

export const getNotificationsService = createAsyncThunk(
  "getNotification-settings",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const token = getState().auth.token;
    try {
      let url = `${API_BASE_URL}notification/status`;
      let res = await API.get(token)(url, payload);
      return { getNotifications: res?.data, status: true };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateNotificationSettings = createAsyncThunk(
  "update-notification-settings",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const token = getState().auth.token;
    try {
      let url = `${API_BASE_URL}notification/updateStatus`;
      let res = await API.post(token)(url, payload);
      dispatch(
        setToast({
          message: res?.message,
          success: true,
          error: false,
        })
      );
      res?.status === true && (await dispatch(notificatiosnListService()));
    } catch (e) {
      dispatch(
        setToast({
          message: e?.message,
          success: false,
          error: true,
        })
      );
      if ((e.message = "Unauthorize Request")) {
        setJwtExpireError(dispatch, e);
      }

      return rejectWithValue(e);
    }
  }
);
