import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../Api/Index";
import { setToast } from "../Slices/CommonToast.Slice";
import { API_BASE_URL } from "../../../Common/Constant/Index";
import { getSecretKey } from "../../../Components/Pages/Dashboard/Overview";

export const regeneratekey = createAsyncThunk(
   "regenerateKey-service",
   async (payload, { rejectWithValue, dispatch, getState }) => {
      const token = getState()?.auth?.token;
      try {
         let url = `${API_BASE_URL}dashboard/regenerateKey`;
         let res = await API.put(token)(url, payload);
         dispatch(
            setToast({
               message: res?.message,
               success: true,
               error: false,
            })
         );

         await getSecretKey(res?.data?.securityKey);
         // return { securityKey: res?.data?.SecurityKey, status: true };
      } catch (e) {
         dispatch(
            setToast({
               message: e?.message,
               success: false,
               error: true,
            })
         );
         return rejectWithValue(e);
      }
   }
);
