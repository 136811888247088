import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../Api/Index";
import { setToast } from "../Slices/CommonToast.Slice";
import { API_BASE_URL } from "../../../Common/Constant/Index";
import { setJwtExpireError } from "../../../Common/CommonFuntion";

export const forgetPasswordtService = createAsyncThunk(
  "forgetPassword-service",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `${API_BASE_URL}auth/forgotPassword`;
      let res = await API.post(payload)(url, payload);

      res?.status == true &&
        dispatch(
          setToast({
            message: res?.message,
            success: true,
            error: false,
          })
        );
    } catch (e) {
      dispatch(
        setToast({
          message: e?.message,
          success: false,
          error: true,
        })
      );
      if ((e.message = "Unauthorize Request")) {
        setJwtExpireError(dispatch, e);
      }
      return rejectWithValue(e);
    }
  }
);
