import React from "react";
import { Table } from "antd";
function NotificationTable({ notificationData }) {
  const columns = [
    {
      title: "Notification",
      dataIndex: "notification",
      key: "notification",

      render: (text, record) => {
        return (
          <>
            <p>
              {record?.notification_type == null ? (
                <span>
                  API Alert: Your CoinMarketCap API subscription has been
                  cancelled due to a billing issue.
                </span>
              ) : (
                record?.notification_type
              )}
            </p>
            <span>{record?.message}</span>
          </>
        );
      },
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text, record) => {
        return (
          <>
            <span>{new Date(record?.created_at).toLocaleDateString()}</span>,{" "}
            <span>{new Date(record?.created_at).toLocaleTimeString()}</span>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        size="middle"
        columns={columns}
        dataSource={notificationData && notificationData}
        pagination={false}
        scroll={{ y: "calc(100vh - 500px)" }}
        className="notificationtable"
      />
    </>
  );
}

export default NotificationTable;
