import { createSlice } from "@reduxjs/toolkit";
import { registerService } from "../Services/Register.Service";
import { usersService } from "../Services/Users.Service";
const initialState = {
  loading: false,
  error: false,
  users: [],
};

const usersSlice = createSlice({
  name: "users-slice",
  initialState,
  reducers: {},
  extraReducers: {
    [usersService.pending]: (state, action) => {
      state.loading = true;
    },
    [usersService.fulfilled]: (state, action) => {
      state.users = action?.payload?.users;
      state.loading = false;
    },

    [usersService.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {} = usersSlice.actions;
export default usersSlice.reducer;
