import { Modal, Popover } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { UserOutlined, CaretDownOutlined } from "@ant-design/icons";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../../Utilities/Redux/Slices/CommonToast.Slice";
function RightNavbar() {
  const { token } = useSelector((state) => state?.auth);
  const name = useSelector((state) => state?.users?.users?.getData?.name);
  const dispatch = useDispatch();

  const [login, setlogin] = useState(true);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  function logoutFn() {
    dispatch({ type: "CLEAR_STATE" });
    
    dispatch(
      setToast({
        message: "Logout successfully",
        success: true,
        error: false,
      })
    );
  }


  const content = (
    <div className="headerDropdownItems">
      <Link to={"/adminControls"} className="headerLinkStyles__links">
        Account Settings
      </Link>
      <Link
        to={"#"}
        className="headerLinkStyles__links"
        onClick={() => {

          setLogoutModalOpen(true);
        }}
      >
        Logout
      </Link>
    </div>
  );
  return (
    <div className="headerLinkStyles">
      {token == null ? (
        <>
          <Link to={"/pricing"} className="headerLinkStyles__links">
            Pricing
          </Link>
          <Link to={"/"} className="headerLinkStyles__links">
            Login
          </Link>
          <Link to={"/register"} className="headerLinkStyles__links">
            Regsiter
          </Link>
        </>
      ) : (
        <React.Fragment>
          <Popover content={content} trigger="hover">
            <div className="headerLinkStyles_user">
              {name} ! <UserOutlined /> <CaretDownOutlined />
            </div>
          </Popover>
        </React.Fragment>
      )}

      <Modal
        id="logoutModal"
        centered
        visible={logoutModalOpen}
        onOk={() => logoutFn()}
        onCancel={() => setLogoutModalOpen(false)}
        width={500}
        okText={"Yes"}
        cancelText={"No"}
      >
        <p style={{ fontSize: "18px" }}>Are you sure want to logout?</p>
      </Modal>
    </div>
  );
}

export default RightNavbar;
