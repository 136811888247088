import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../Api/Index";
import { API_BASE_URL } from "../../../Common/Constant/Index";
import { setJwtExpireError } from "../../../Common/CommonFuntion";

export const apiListService = createAsyncThunk(
  "apiList-service",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const token = getState()?.auth?.token;
    try {
      let url = `${API_BASE_URL}dashboard/api-list`;
      let res = await API.get(token)(url, payload);

      return { apiListData: res?.data };
    } catch (e) {
      if ((e.message = "Unauthorize Request")) {
        setJwtExpireError(dispatch, e);
      }
      return rejectWithValue(e);
    }
  }
);
